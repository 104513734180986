/* estilos para el infowindow de googlemaps */
.gm-style .gm-style-iw-d::-webkit-scrollbar-track, 
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after { 
    background: rgb(4, 43, 85);
    display: inline-block !important;
}
.gm-style .gm-style-iw-tc::after {   background: rgb(4, 43, 85); }
.gm-ui-hover-effect>span{
    background-color: white;
}


.gm-style-iw-d{
    display: inline-block !important;
}

.gm-style-iw {
    display: inline-block !important;
    max-height: none !important;
    overflow: auto !important;
}