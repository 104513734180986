.custom-progress::-webkit-progress-value {
    background-color: #76ccff; /* Cambia 'blue' por el color que desees */
}

.dashboard {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 300px 1fr 1fr;
    grid-template-rows: 70px 1fr 0px;
    grid-template-areas: 
        "header header header"
        "menu main main"
        "menu footer footer";
    background: #003968;
    transition: all .3s;
    caret-color: transparent;
}

.menu {
    grid-area: menu;
}

.header {
    grid-area: header;
}

.main {
    grid-area: main;
    /* background: #0d0d0e; */
    background:  radial-gradient(50% 50% at 50% 50%, rgb(16, 25, 33) 0%, rgb(0, 0, 0) 100%);

}

.footer {
    grid-area: footer;
    background: #121212;
}

.menu {
    transition: all .3s;
    left: 0px;
    z-index: 100000000000000000;
}

.menu.active {
    left: 0px!important;
}

@media screen and (max-width: 1024px) { 
    .dashboard {
        grid-template-areas: 
            "header header header"
            "main main main"
            "footer footer footer";
        transition: all .3s;
    }

    .menu {
        left: -300px;
    }  

    .menu.active {
        left: 0px!important;
    }
}

.menu >.ul-nav {
    height: 100%;
}

.menu.active >.ul-nav {
    width: 285px!important;
}

.icon-split {
    transition: all .3s;
}

.fondo-negro {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #000;
    opacity: 0.3;
}

.ul-nav {
    width: 285px;
    padding-bottom: 60px;
    background-color: #fff;
    z-index: 999;
    overflow-y: auto;
    will-change: transform;
    backface-visibility: hidden;
    padding: 0;
    /* box-shadow: 0px 10px 20px -4px #000; */
    transition: all .5s;
}


/*acciones li*/
.ul-nav-li {
    display: block;
    user-select: none;
    list-style: none;
    /* line-height: 48px; */
    font-size: .9rem;
}

.li-nav-div {
    padding: 0 0rem;
    line-height: inherit;
    cursor: pointer;
    transition: all .3s;
    height: 36px;
}

    /* .li-nav-div:hover {
        background: #DBDBDB;
        opacity: 1;
    } */

    .li-nav-div i {
        width: 1rem;
        font-size: 1rem;
        display: inline-block;
        text-align: center;
        margin-right: .3rem;
    }
/*acciones li*/

.li-nav-div{
    background: #003968;
    /* margin-bottom: 5px; */
    padding: 0 8px!important;
    /* border-top:#000000 0.1rem solid; */
    border-top:#000000 0.1rem solid;

    /* border-bottom: #adb4d3 thick solid; */
    /* border-bottom:#c0bdbd 0.01rem solid ; */

}
.li-nav-div-submenu  {
    background: #101921;
    /* background: #00a0df02; */


    
    /* margin-bottom: 5px; */
    padding: 0 8px!important;
}

/*Sub menus*/
.ul-submenu {
    padding: 0;
}

.ul-nav-li-submenu {
    display: block;
    list-style: none;
    /* line-height: 45px; */
    font-size: .9rem;
}

.li-nav-div-submenu {
    padding: 0 0rem;
    line-height: inherit;
    cursor: pointer;
    transition: all .3s;
    height: 32px;
    border-top: 4px solid #101921
}

    /* .li-nav-div-submenu:hover {
        
        background :#bbdefb;
        opacity: 1;
    } */

    .li-nav-div-submenu i {
        width: 1rem;
        font-size: 1rem;
        display: inline-block;
        text-align: center;
        margin-right: .3rem;
    }
/*Sub menus*/

/*mostrar submenu*/
.active-li {
    background-color: #DBDBDB;
}

.ul-submenu {
    border: 0;
    background-color: #fff;
    display: block;
}

.active-submenu {
    background: #003968;
}

.nav-link {
    padding: 0 0 0 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-link.active {
    color: #ffffff;
}

.icon-menu {
    transition: all 0.5s;
}

.nav-link.active > div > .icon-menu {
    transform: rotate(-180deg);
}

.li-a-submenu.active {
    background: #00A0DF;
    color: #ffff;
    /* border-radius: 4px; */
}

.nav-link:hover {
    /* background: #264258; */
    background: #383838;
    color: #fff;
    /* border-radius: 4px; */
}

.nav-link.active:hover {
    background: #025779;
    color: #ffffff;
    /* border-radius: 4px; */
}

.text-menu {
}

.text-sub-menu {
    padding-left: 20px;
}


/* TABLE CSS */
.table {
    width: 100%;
}

.table tr th {
    background: #003968;
    color: white;
    padding-top: 5px !important; /*Era 10 px*/
    padding-bottom: 5px !important; /*Era 10 px*/
    /* border-bottom: 1px solid black !important; */
    font-size: 12px;
    /* width: 100%; */
}

.table thead tr {
    height: 33px;
}

.table tbody tr {
    height: 30px;
}

.table tbody tr:hover {
    background: #264258!important;
    cursor: pointer;
}

.table tbody tr td {
    font-size: 13px;
    height: 15px
    /* border-top: 1px solid #ddd; */
}

.table thead tr th:hover {
    cursor: pointer;
}

.table tbody tr:nth-child(odd) {
    background-color: #222222;
}

.table tbody tr:nth-child(even) {
    background-color: #121212;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
    padding: 8px;
    padding-top: 3px;
    /* line-height: 1.42857143; */
    vertical-align: middle;
    padding-bottom: 0px;
}

.table thead tr th { 
    position: sticky!important;
    top: 0!important;
    z-index: 10!important;
    /* border-bottom: 1px solid #000!important; */
}

.table thead tr:nth-child(2) th { 
    top: 30px!important;
}

.contenedor-tabla {
    height: calc(100% - 48px - 28px);
    overflow: auto;
}


/* Recargas */
.form-content > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}


/* SWITCH DEL MENU MONITOREO/INCIDENTES - BOTON SEGUIMIENTO */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 25px;
    outline: none;
}
.switch input {
    position: absolute;
    top: -99999px;
    left: -99999px;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}
.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}
input:checked + .slider {
    background-color: #2196F3;
}
input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}


/* RADIOBUTTONS DEL MENU MONITOREO/INCIDENTES - BOTON SEGUIMIENTO */
.formularioRadio {
}
.formularioRadio h2 {
    font-size: 16px;
    color: #dfeb39;
    margin-bottom: 1px;
    margin-left: 1px; }
.formularioRadio > div {
    padding: 5px 0;
    border-bottom: 0px solid #ccc; }
.formularioRadio .radio label,
.formularioRadio .checkbox label {
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    position: relative;
    padding: 5px 30px 5px 35px;
    font-size: 1em;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .formularioRadio .radio label:hover,
    .formularioRadio .checkbox label:hover {
    background: rgba(65, 112, 150, 0.1); }
    .formularioRadio .radio label:before,
    .formularioRadio .checkbox label:before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    position: absolute;
    left: 15px;
    border-radius: 50%;
    background: none;
    border: 3px solid #ffffff; }
.formularioRadio input[type="radio"] {
    display: none; }
    .formularioRadio input[type="radio"]:checked + label:before {
    display: none; }
    .formularioRadio input[type="radio"]:checked + label {
    padding: 5px 15px;
    background: #0774da;
    border-radius: 2px;
    color: #fff; }
.formularioRadio .checkbox label:before {
    border-radius: 3px; }
.formularioRadio .checkbox input[type="checkbox"] {
    display: none; }
    .formularioRadio .checkbox input[type="checkbox"]:checked + label:before {
    display: none; }
    .formularioRadio .checkbox input[type="checkbox"]:checked + label {
    background: #ffd900;
    color: #fff;
    padding: 5px 15px; }


/* ESTILOS INPUT */
.caret-input{
    cursor:caret;
    caret-color:white;
}

    /* FIN ESTILOS INPUT */

/* .select-filtro{
    color:white;
    height: 30px;
    border-radius: 0.25rem;
    padding-left: 1rem; (16px) 
    padding-right: 1rem; (16px)
    width: 100%;
    background-color: rgb(39 39 42);
    font-size: 0.75rem; (12px)
    line-height: 1rem; (16px) 
    
} */

/* ESTILO DEL SCROLLBAR  */
.containerScroll::-webkit-scrollbar {
    width: 10px;     /* Tamaño del scroll en vertical */
    height: 10px;    /* Tamaño del scroll en horizontal */
    /* display: none;  Ocultar scroll */
}

.containerScroll::-webkit-scrollbar-thumb {
    background: #2e5289;
    /* border-radius: 4px; */
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.containerScroll::-webkit-scrollbar-thumb:hover {
    background: #5376ac;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.containerScroll::-webkit-scrollbar-thumb:active {
    background-color: #3e639b;
}
.containerScroll::-webkit-scrollbar-track {
    background: #474545;
    /* border-radius: 4px; */
}

/* Cambiamos el fondo cuando esté en active o hover */
.containerScroll::-webkit-scrollbar-track:hover,
.containerScroll::-webkit-scrollbar-track:active {
    background: #474545;
}
 /* PARA DISPOSITIVOS MOBILES */
@media screen and (max-width: 639px) { 
    .containerScroll::-webkit-scrollbar {
        width: 1px;     /* Tamaño del scroll en vertical */
        height: 0px;    /* Tamaño del scroll en horizontal */
        /* display: none;  Ocultar scroll */
    }
    
    .containerScroll::-webkit-scrollbar-thumb {
        background: #2e5289;
        /* border-radius: 4px; */
    }
    
    /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
    .containerScroll::-webkit-scrollbar-thumb:hover {
        /* background: #ac5e53; */
        /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2); */
    }
    
    /* Cambiamos el fondo cuando esté en active */
    .containerScroll::-webkit-scrollbar-thumb:active {
        /* background-color: #3e639b; */
    }
    .containerScroll::-webkit-scrollbar-track {
        background: #474545;
        /* border-radius: 4px; */
    }
    
    /* Cambiamos el fondo cuando esté en active o hover */
    .containerScroll::-webkit-scrollbar-track:hover,
    .containerScroll::-webkit-scrollbar-track:active {
        background: #474545;
    }

}

.classNameInput {
  height: 30px;
  text-color: white;
  border-radius: 4px;
  padding-left: 4px;
  width: 250px;
  background-color: #27272a; /* Sustituye esto con el valor exacto de bg-zinc-800 en tu paleta de colores */
  font-size: 0.75rem; /* Equivalente a text-xs en Tailwind */
  outline: none;
  caret-color: white;
  padding: 0px 10px;
}

.classNameInput:focus {
  ring: 1px;
  ring-color: #3b82f6; /* Sustituye esto con el valor exacto de focus:ring-blue-500 en tu paleta de colores */
}




/* FIN DEL ESTILO DEL SCROLLBAR */


/**INPUT FILE***/

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    border-radius: 10px;
    max-width: 70%;
    font-size: 1rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.25rem 0.5rem;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

.iborrainputfile {
	font-size:16px; 
	font-weight:normal;
	font-family: 'Lato';
}

/* style 1 */

.inputfile-1 + label {
    color: #fff;
    background-color: #0774da;
}

.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
    background-color: #1e64a5;
}


/**********End File Inputs**********/

/* DIV MOIBLE */

.divMovible {
    position: absolute;
    z-index: 9;
}

.divMovibleCabecera {
    padding: 10px;
    cursor: move;
    z-index: 10;
}

/* FIN DIV MOBIBLE */

.custom-header-excel {
    background-color: #f2f2f2;
    font-weight: bold;
    padding: 10px;
  }
  

/* SOLUCION TEMPORAL PARA EL PAPER DE REACT RAPHAEL */
/* .react-raphael{
    height: 100%;
}
.paper-container {
    height: 100%;
} */

@keyframes moveGusanoA {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        transform: translateX(70vw);
        opacity: 0;
    }
}

@keyframes moveGusanoB {
    0% {
        transform: translateX(70vw);
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100% {
        transform: translateX(0);
        opacity: 0;
    }
}

.animated-element-A {
    animation: moveGusanoA 6s linear infinite;
}

.animated-element-B {
    animation: moveGusanoB 6s linear infinite;
}

