.claseDia {
    padding: 2px 10px 3px 10px;
    border: 1px solid black;
    cursor: pointer;
    width:89px
}

.claseDia:hover{
    background: #2e5289;
    
}

.claseDiaSinSeleccionar{
    background:#222222;
}

.claseDiaSeleccionado{
    background: #2e5289;
}