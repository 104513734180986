/* PARA LOS BORDES DE LA TABLA */
#tablaMatrizOperativa{
  border-collapse: separate;
  border-spacing: 0px;
  /* overflow-x: auto; */
  /* display: block; */
  width: 100%;
}
#tablaMatrizOperativa th, #tablaMatrizOperativa td{
  border: 1px solid rgb(81 81 81)
}

/* PARA LA CABECERA */
  /* DESDE EL PRIMERO HACIA ATRAS */
  #tablaMatrizOperativa thead tr:first-child th:first-child{
    z-index: 11 !important;
    position: sticky;
    min-width: 40px;
    max-width: 40px;
    left: 0;
  }
  #tablaMatrizOperativa thead tr:first-child th:nth-child(2){
    z-index: 11 !important;
    position: sticky;
    /* min-width: 120px;
    max-width: 120px; */
    left: 40px;
  }
  /* DATOS EMPRESA */
    #tablaMatrizOperativa thead tr:nth-child(2) th:first-child{
      z-index: 11 !important;
      position: sticky;
      min-width: 100px;
      max-width: 100px;
      left: 40px;
    }
    #tablaMatrizOperativa thead tr:nth-child(2) th:nth-child(2){
      z-index: 11 !important;
      position: sticky;
      min-width: 300px;
      max-width: 300px;
      left: 140px;
    }
    #tablaMatrizOperativa thead tr:nth-child(2) th:nth-child(3){
      z-index: 11 !important;
      position: sticky;
      min-width: 100px;
      max-width: 100px;
      left: 440px;
    }
  #tablaMatrizOperativa thead tr:first-child th:nth-child(3){
    z-index: 11 !important;
    position: sticky;
    min-width: 100px;
    max-width: 100px;
    left: 540px;
  }
  /* DESDE EL ULTIMO HACIA ADELANTE */
  #tablaMatrizOperativa thead tr:first-child th:last-child{
    z-index: 11 !important;
    position: sticky;
    min-width: 200px;
    max-width: 200px;
    right: 0;
  }
  #tablaMatrizOperativa thead tr:first-child th:nth-last-child(2){
    /* width: 15%; */
    z-index: 11 !important;
    position: sticky;
    min-width: 250px;
    max-width: 250px;
    right: 200px;
  }
    /* INDICADORES */
    #tablaMatrizOperativa thead tr:nth-child(2) th:last-child{
      z-index: 11 !important;
      position: sticky;
      min-width: 50px;
      max-width: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      right: 200px;
    }
    #tablaMatrizOperativa thead tr:nth-child(2) th:nth-last-child(2){
      z-index: 11 !important;
      position: sticky;
      min-width: 50px;
      max-width: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      right: 250px;
    }
    #tablaMatrizOperativa thead tr:nth-child(2) th:nth-last-child(3){
      z-index: 11 !important;
      position: sticky;
      min-width: 50px;
      max-width: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      right: 300px;
    }
    #tablaMatrizOperativa thead tr:nth-child(2) th:nth-last-child(4){
      z-index: 11 !important;
      position: sticky;
      min-width: 50px;
      max-width: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      right: 350px;
    }
    #tablaMatrizOperativa thead tr:nth-child(2) th:nth-last-child(5){
      z-index: 11 !important;
      position: sticky;
      min-width: 50px;
      max-width: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      right: 400px;
    }
/* PARA EL CUERPO */
  /* DE ADELANTE HACIA ATRAS */
  #tablaMatrizOperativa tbody tr td:first-child{
    background-color: #222222;
    position: sticky;
    width: 40px;
    left: 0;
  }
  #tablaMatrizOperativa tbody tr td:nth-child(2){
    background-color: #222222;
    position: sticky;
    width: 100px;
    left: 40px;
  }
  #tablaMatrizOperativa tbody tr td:nth-child(3){
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;

    background-color: #222222;
    position: sticky;
    width: 300px;
    left: 140px;
  }
  #tablaMatrizOperativa tbody tr td:nth-child(4){
    background-color: #222222;
    position: sticky;
    width: 100px;
    left: 440px;
  }
  #tablaMatrizOperativa tbody tr td:nth-child(5){
    background-color: #222222;
    position: sticky;
    width: 100px;
    left: 540px;
  }
  /* DE ATRAS HACIA ADELANTE */
  #tablaMatrizOperativa tbody tr td:last-child{
    background-color: #222222;
    position: sticky;
    min-width: 200px;
    max-width: 200px;
    right: 0;
  }
  #tablaMatrizOperativa tbody tr td:nth-last-child(2){
    background-color: #222222;
    position: sticky;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 50px;
    max-width: 50px;
    right: 200px;
  }
  #tablaMatrizOperativa tbody tr td:nth-last-child(3){
    background-color: #222222;
    position: sticky;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 50px;
    max-width: 50px;
    right: 250px;
  }
  #tablaMatrizOperativa tbody tr td:nth-last-child(4){
    background-color: #222222;
    position: sticky;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 50px;
    max-width: 50px;
    right: 300px;
  }
  #tablaMatrizOperativa tbody tr td:nth-last-child(5){
    background-color: #222222;
    position: sticky;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 50px;
    max-width: 50px;
    right: 350px;
  }
  #tablaMatrizOperativa tbody tr td:nth-last-child(6){
    background-color: #222222;
    position: sticky;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 50px;
    max-width: 50px;
    right: 400px;
  }